import React, { ReactNode } from 'react'

export interface iBrand {
  black?: boolean
  width?: string | number
  height?: string | number
}

interface iBrandSelector extends React.SVGProps<SVGSVGElement> {
  children: ReactNode
}

const BaseBrand: React.FC<iBrandSelector> = ({ children, ...svgProps }) => {
  return (
    <svg {...svgProps} xmlns="http://www.w3.org/2000/svg">
      {children}
    </svg>
  )
}

export default BaseBrand
