import isValidMongoId from '@comparacar/lib/src/utils/isValidMongoId'
import DeleteIcon from '@mui/icons-material/Delete'
import IconButton from '@mui/material/IconButton'
import Link from '@mui/material/Link'
import List from '@mui/material/List'
import ListItem from '@mui/material/ListItem'
import ListItemAvatar from '@mui/material/ListItemAvatar'
import ListItemText from '@mui/material/ListItemText'
import React from 'react'
import { useSetRecoilState, useRecoilValue } from 'recoil'

import BrandSelector from '@comparacar/components/src/atoms/BrandSelector'
import basketState from '@comparacar/components/src/state/basketState'
import { getProvider } from '@comparacar/components/src/state/brands'

const isNotStartsAt = (value: string) => isValidMongoId(value)

const BasketItem: React.FC<{ item: ResultCard; hideLinks?: boolean }> = ({ item, hideLinks }) => {
  const brand = useRecoilValue(getProvider(item.brand as string))
  const setBasketItem = useSetRecoilState(basketState)

  return (
    <ListItem
      data-cy={`basketItem-${item.id}`}
      key={item.id}
      secondaryAction={
        <IconButton
          data-cy="removeBasketItem"
          edge="end"
          onClick={() => {
            setBasketItem(currentState => [...currentState.filter(i => item.id !== i.id)])
          }}
        >
          <DeleteIcon />
        </IconButton>
      }
    >
      <ListItemAvatar sx={{ mr: 1 }}>
        <BrandSelector name={brand.slug} width={70} height={36} />
      </ListItemAvatar>
      <ListItemText
        primary={
          <>
            {hideLinks || !isNotStartsAt(item.id) ? (
              <b>{item.title}</b>
            ) : (
              <b>
                {' '}
                <Link href={item.localLink}>{item.title}</Link>
              </b>
            )}{' '}
            - {item.price}
          </>
        }
        secondary={`${item.description} / ${item.description2}`}
      />
    </ListItem>
  )
}

export default function BasketList({ hideLinks }: { hideLinks?: boolean }) {
  const basket = useRecoilValue(basketState)

  if (!Boolean(basket.length)) return null

  return (
    <List sx={{ width: '100%', overflow: 'auto', maxHeight: 300 }}>
      {basket.map(item => (
        <BasketItem key={item.id} item={item} hideLinks={hideLinks} />
      ))}
    </List>
  )
}
