import BasketButton from '@comparacar/components/src/organisms/BasketButton'
import Footer from '@comparacar/components/src/organisms/Footer'
import Header from '@comparacar/components/src/organisms/Header'
import { fetchProviderState } from '@comparacar/components/src/state/brands'
import Container from '@mui/material/Container'
import { useRouter } from 'next/router'
import React, { ReactNode } from 'react'
import { useRecoilValue } from 'recoil'

interface iLayout {
  children: ReactNode
}

const Layout: React.FC<iLayout> = ({ children }) => {
  useRecoilValue(fetchProviderState)
  const router = useRouter()

  return (
    <>
      <Header />
      {!router.asPath.startsWith('/assinar/lead') &&
      (router.asPath.startsWith('/assinar') || router.asPath.startsWith('/carmatch/result')) ? (
        <BasketButton />
      ) : null}
      <Container>{children}</Container>
      <Footer />
    </>
  )
}

export default Layout
