import VerifiedIcon from '@mui/icons-material/Verified'
import Box from '@mui/material/Box'
import Popover from '@mui/material/Popover'
import Typography from '@mui/material/Typography'
import Link from 'next/link'
import React, { useState } from 'react'

import Image from '../Image'

interface iBrandSelector {
  name?: string
  href?: string
  width: number
  height: number
  isAuthorizedPartner?: boolean
}

const BrandImage: React.FC<{
  id?: string
  name: string
  width: number
  height: number
  isAuthorizedPartner?: boolean
}> = ({ id, isAuthorizedPartner, name, height, width }) => {
  const [anchorEl, setAnchorEl] = useState<HTMLDivElement | null>(null)

  const handleClose = () => {
    setAnchorEl(null)
  }

  const open = Boolean(anchorEl)

  return (
    <Box sx={{ position: 'relative' }}>
      <Image
        id={id}
        src={`https://ik.imagekit.io/comparacar/brands/${name}.svg`}
        alt={name}
        height={height}
        width={width}
      />
      {isAuthorizedPartner && (
        <>
          <Box
            sx={{ position: 'absolute', top: -15, right: -15, cursor: 'pointer' }}
            onClick={event => {
              setAnchorEl(event.currentTarget)
            }}
          >
            <VerifiedIcon color="primary" />
          </Box>
          <Popover
            open={open}
            anchorEl={anchorEl}
            onClose={handleClose}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'left'
            }}
          >
            <Typography sx={{ p: 2 }}>Parceiro autorizado.</Typography>
          </Popover>
        </>
      )}
    </Box>
  )
}

const BrandSelector: React.FC<iBrandSelector> = ({ name = 'empty', href, height, width, isAuthorizedPartner }) => {
  if (href) {
    return (
      <Link href={href} legacyBehavior>
        <div style={{ cursor: 'pointer' }}>
          <BrandImage
            id={`brand-${name}`}
            name={name}
            height={height}
            width={width}
            isAuthorizedPartner={isAuthorizedPartner}
          />
        </div>
      </Link>
    )
  }
  return (
    <BrandImage
      id={`brand-${name}`}
      name={name}
      height={height}
      width={width}
      isAuthorizedPartner={isAuthorizedPartner}
    />
  )
}

export default BrandSelector
