import { gql } from '@apollo/client'

import client from '../apollo'

export default async function fetchSubscriptionsSource(): Promise<Provider[]> {
  const { data } = await client.query<{
    carsSubscriptionsSources: Provider[]
  }>({
    query: gql(`
      query carsSubscriptionsSources {
        carsSubscriptionsSources {
          authorizedPartner
          detail {
            bullets
            contractUrl
            qa {
              answer
              question
            }
            slogan
          }
          id
          label
          name
          slug
        }
      }
    `)
  })

  return data.carsSubscriptionsSources
}
