import CloseIcon from '@mui/icons-material/Close'
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart'
import ShoppingCartCheckoutIcon from '@mui/icons-material/ShoppingCartCheckout'
import { useMediaQuery, useTheme } from '@mui/material'
import Badge from '@mui/material/Badge'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import Dialog from '@mui/material/Dialog'
import DialogContent from '@mui/material/DialogContent'
import IconButton from '@mui/material/IconButton'
import Link from '@mui/material/Link'
import { useRouter } from 'next/router'
import { useState, useEffect } from 'react'
import { useRecoilValue } from 'recoil'

import BasketList from '@comparacar/components/src/organisms/BasketList'
import basketState from '@comparacar/components/src/state/basketState'

export default function BasicSpeedDial() {
  const [showBasket, setShowBasket] = useState(false)
  const basket = useRecoilValue(basketState)
  const router = useRouter()

  const isDesktop = useMediaQuery('(min-width:900px)')

  const theme = useTheme()

  const handleCloseBasket = () => {
    setShowBasket(false)
  }

  useEffect(() => {
    if (!Boolean(basket.length)) setShowBasket(false)
  }, [basket])

  if (!Boolean(basket.length)) return null

  return (
    <>
      <Box
        sx={{
          position: 'fixed',
          right: { xs: 16, sm: 24 },
          left: { xs: 16, sm: 'unset' },
          bottom: 24,
          fontSize: 48,
          zIndex: theme.zIndex.fab
        }}
      >
        <Button
          data-cy="basketButton"
          onClick={() => setShowBasket(true)}
          component={Link}
          fullWidth
          variant="contained"
          color="warning"
          target="_blank"
          endIcon={
            <Badge badgeContent={basket.length} color="primary">
              <ShoppingCartIcon />
            </Badge>
          }
        >
          Continuar
        </Button>
      </Box>
      <Dialog open={showBasket} onClose={handleCloseBasket} maxWidth="sm" fullWidth fullScreen={!isDesktop}>
        <DialogContent>
          <Box sx={{ position: 'absolute', right: '16px', top: '16px' }}>
            <IconButton sx={{ color: 'text.primary' }} onClick={handleCloseBasket}>
              <CloseIcon />
            </IconButton>
          </Box>
          <BasketList />
          <Box>
            <Button
              data-cy="followUp"
              onClick={() => {
                router.push({
                  pathname: '/assinar/lead',
                  query: {
                    origin: basket[0]?.brand || ' Fale agora'
                  }
                })
                handleCloseBasket()
              }}
              startIcon={<ShoppingCartCheckoutIcon />}
              fullWidth
              variant="contained"
            >
              Solicitar proposta
            </Button>
          </Box>
        </DialogContent>
      </Dialog>
    </>
  )
}
