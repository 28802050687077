import { atom } from 'recoil'

const effect = ({ setSelf, onSet }) => {
  const key = 'basket'
  let basket: string | null = sessionStorage.getItem(key) || null
  const savedValue = basket ? JSON.parse(basket) : null
  if (savedValue != null) {
    setSelf(savedValue)
  }

  onSet((newValue, _, isReset) => {
    isReset ? sessionStorage.removeItem(key) : sessionStorage.setItem(key, JSON.stringify(newValue))
  })
}

const basketState = atom<ResultCard[]>({
  key: 'basketState',
  default: [],
  effects: [effect]
})

export default basketState
