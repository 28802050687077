import fetchSubscriptionsSource from '@comparacar/lib/src/api/comparacar/subscriptionsSource'
import { selector, atom, selectorFamily } from 'recoil'

import engineState from './engine'

const brandsList: Partial<Provider>[] = [
  { slug: 'mercadolivre', name: 'MercadoLivre', label: 'Mercadolivre' },
  { slug: 'icarros', name: 'ICarros', label: 'ICarros' },
  { slug: 'mobiauto', name: 'Mobiauto', label: 'Mobiauto' },
  { slug: 'meucarronovo', name: 'MeuCarroNovo', label: 'Meucarronovo' },
  { slug: 'usadosbr', name: 'UsadosBR', label: 'UsadosBR' }
]

export const fetchProviderState = atom<Provider[]>({
  key: 'fetchProviderState',
  default: selector({
    key: 'fetchProviderState/default',
    get: async () => {
      const subscriptionProvider = (await fetchSubscriptionsSource()).map<Provider>(item => ({
        ...item,
        type: 'subscribe'
      }))
      const advertisementProvider = brandsList.map<Provider>(item => ({
        id: item.slug,
        name: item.name,
        slug: item.slug,
        label: item.label,
        type: 'buy',
        authorizedPartner: !item.authorizedPartner
      }))
      return [...subscriptionProvider, ...advertisementProvider]
    }
  })
})

export const fetchFilterProviders = selectorFamily<Provider[], engine>({
  key: 'getProvider',
  get:
    engine =>
    ({ get }) =>
      get(fetchProviderState).filter(item => item.type === engine)
})

export const carouselIconsState = selector<Partial<Provider & { href: string }>[]>({
  key: 'carouselIconsState',
  get: ({ get }) => {
    const engine = get(engineState)
    const providers = get(fetchProviderState)
    if (engine === 'subscribe') {
      return providers
        .filter(item => item.type === 'subscribe')
        .map(item => ({
          slug: item.slug,
          href: `/assinar/carro?source=${item.name}`
        }))
    }
    return providers
      .filter(item => item.type === 'buy')
      .map(item => ({
        slug: item.slug,
        href: `/comprar/carro?source=${item.name}`
      }))
  }
})

export const getProvider = selectorFamily<Provider, string>({
  key: 'getProvider',
  get:
    source =>
    ({ get }) =>
      get(fetchProviderState).find(item => item.name === source)
})
