import ArrowBackOutlinedIcon from '@mui/icons-material/ArrowBackOutlined'
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft'
import ChevronRightIcon from '@mui/icons-material/ChevronRight'
import MenuIcon from '@mui/icons-material/Menu'
import { useTheme as theme } from '@mui/material'
import AppBar from '@mui/material/AppBar'
import Box from '@mui/material/Box'
import Container from '@mui/material/Container'
import Divider from '@mui/material/Divider'
import Drawer from '@mui/material/Drawer'
import IconButton from '@mui/material/IconButton'
import Link from '@mui/material/Link'
import List from '@mui/material/List'
import ListItem from '@mui/material/ListItem'
import ListItemButton from '@mui/material/ListItemButton'
import ListItemIcon from '@mui/material/ListItemIcon'
import ListItemText from '@mui/material/ListItemText'
import Toolbar from '@mui/material/Toolbar'
import NextLink from 'next/link'
import { useRouter } from 'next/router'
import React, { Fragment, useState } from 'react'

import ComparaCarIcon from '@comparacar/components/src/atoms/icons/brands/ComparaCar'
import SearchInput from '@comparacar/components/src/atoms/SearchInput'

const drawerWidth = 327

const links = [
  // {
  //   href: '/comprar',
  //   title: 'Comprar'
  // },
  {
    href: '/assinar',
    title: 'Carros por assinatura'
  },
  {
    href: '/avalieseucarro',
    title: 'Avalie seu carro'
  },
  {
    href: '/conteudo',
    title: 'Conteúdo'
  },
  {
    href: '/sobre',
    title: 'Sobre'
  }
]

const drawerLinks = [
  ...links,
  {
    href: '/termos_de_uso',
    title: 'Termos de uso'
  }
]

interface iHeader {}

const Header: React.FC<iHeader> = () => {
  const router = useRouter()
  const [mobileOpen, setMobileOpen] = useState(false)
  const [searchOpen, setSearchOpen] = useState(false)

  const background = theme().palette.background.paper

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen)
  }

  const drawer = (
    <Box sx={{ mt: 3 }}>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          p: 3
        }}
      >
        <ComparaCarIcon height="25.45" width="145" />
        <IconButton onClick={handleDrawerToggle}>
          <ChevronLeftIcon color="primary" />
        </IconButton>
      </Box>
      <Divider />
      <List sx={{ paddingX: 3, pt: 6 }}>
        {drawerLinks.map((link, i) => (
          <Fragment key={i}>
            <ListItem
              disablePadding
              sx={{
                '&:not(:first-child)': {
                  pt: 3
                }
              }}
            >
              <ListItemButton
                onClick={() => {
                  handleDrawerToggle()
                  router.push(link.href)
                }}
                sx={{ paddingX: 0 }}
              >
                <ListItemText primary={link.title} />
                <ListItemIcon sx={{ justifyContent: 'end' }}>
                  <ChevronRightIcon />
                </ListItemIcon>
              </ListItemButton>
            </ListItem>
            <Divider />
          </Fragment>
        ))}
      </List>
    </Box>
  )

  return (
    <>
      <AppBar
        position="static"
        sx={{
          backgroundColor: background,
          boxShadow: 'none'
        }}
      >
        <Container maxWidth="xl">
          <Toolbar sx={{ height: '80px' }}>
            {searchOpen ? (
              <>
                <IconButton
                  color="primary"
                  aria-label="open menu"
                  edge="start"
                  onClick={() => setSearchOpen(false)}
                  sx={{ display: { md: 'none' }, mr: 3 }}
                >
                  <ArrowBackOutlinedIcon />
                </IconButton>
                <SearchInput
                  onSelect={option => {
                    const newQuery = option.value.split('/')
                    router.push({
                      pathname: router.pathname,
                      query: {
                        ...router.query,
                        slug: newQuery
                      }
                    })
                    setSearchOpen(false)
                  }}
                />
              </>
            ) : (
              <>
                <NextLink href="/" passHref>
                  <ComparaCarIcon />
                </NextLink>
                <Box sx={{ flexGrow: 1 }} />
                {links.map((link, i) => (
                  <NextLink key={i} href={link.href} passHref legacyBehavior>
                    <Link color="textPrimary" variant="body1" sx={{ ml: 4, display: { xs: 'none', md: 'block' } }}>
                      {link.title}
                    </Link>
                  </NextLink>
                ))}

                <IconButton
                  color="primary"
                  aria-label="open menu"
                  edge="start"
                  onClick={handleDrawerToggle}
                  sx={{ display: { md: 'none' } }}
                >
                  <MenuIcon />
                </IconButton>
              </>
            )}
          </Toolbar>
        </Container>
      </AppBar>
      <Drawer
        variant="temporary"
        open={mobileOpen}
        anchor="left"
        onClose={handleDrawerToggle}
        ModalProps={{
          keepMounted: true // Better open performance on mobile.
        }}
        sx={{
          display: { sm: 'block', md: 'none' },
          '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth }
        }}
      >
        {drawer}
      </Drawer>
    </>
  )
}

export default Header
